const { hot } = require("react-hot-loader/root")

// prefer default export if available
const preferDefault = m => m && m.default || m


exports.components = {
  "component---src-components-templates-home-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/HomePage.js"))),
  "component---src-components-templates-landing-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/LandingPage.js"))),
  "component---src-components-templates-listing-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/ListingPage.js"))),
  "component---src-components-templates-dining-detail-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/DiningDetailPage.js"))),
  "component---src-components-templates-dining-menu-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/DiningMenuPage.js"))),
  "component---src-components-templates-event-calendar-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/EventCalendarPage.js"))),
  "component---src-components-templates-event-and-promo-detail-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/EventAndPromoDetailPage.js"))),
  "component---src-components-templates-event-venue-detail-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/EventVenueDetailPage.js"))),
  "component---src-components-templates-form-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/FormPage.js"))),
  "component---src-components-templates-not-found-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/NotFoundPage.js"))),
  "component---src-components-templates-preview-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/PreviewPage.js"))),
  "component---src-components-templates-unpublished-preview-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/src/components/templates/UnpublishedPreviewPage.js"))),
  "component---cache-dev-404-page-js": hot(preferDefault(require("/data/app/packages/gatsby-site/.cache/dev-404-page.js")))
}

